<template>
  <div class="yuebao">
    <!-- 导航栏 -->
    <van-nav-bar
      title="Yu'E Bao"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >Yu'E Bao</span
        >
      </template>
      <span
        slot="right"
        @click="$router.push('/yuebaoRule')"
        :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }"
        >Rule</span
      >
    </van-nav-bar>

    <div class="Amount-box">
      <div class="Amount">Amount(₫)</div>
      <div class="time">0.00</div>
      <div class="Earnings">Earnings yesterday <span>₫ 0.00</span></div>
    </div>
    <div class="Income-box">
      <div class="Accumulated">
        <div class="income-item">
          <span class="title">Total revenue(₫)</span>
          <span>0.00</span>
        </div>
        <div
          class="Roll-out btn"
          @click="
            $router.push({ path: '/Rollout', query: { title: 'Roll out' } })
          "
        >
          Roll Out
        </div>
      </div>
      <div class="annualized">
        <div class="income-item">
          <span class="title">QiRi annualized(%)</span>
          <span>0.00</span>
        </div>
        <div
          class="Shift-to btn"
          @click="
            $router.push({ path: '/Rollout', query: { title: 'shift to' } })
          "
        >
          Shift to
        </div>
      </div>
    </div>
    <div class="detailed">
      <span class="returns">Returns detailed</span>
    </div>
    <!-- tab标签区域 -->
    <div class="tab-box">
      <van-tabs v-model="active" title-active-color="#EE6039">
        <van-tab :title="tabbarPageInfo[0].title">No data</van-tab>
        <van-tab :title="tabbarPageInfo[1].title">No data</van-tab>
        <van-tab :title="tabbarPageInfo[2].title">No data</van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { yuebaoDetaileList, yuebaoDetail } from '@/api/yuebao.js'
export default {
  name: 'YuBao',
  components: {},
  data() {
    return {
      active: 0,
      // language-ch 近七天 一หนึ่งสถานที่月 三หนึ่งสถานที่月
      tabbarPageInfo: [
        {
          title: 'In seven days',
          type: '7day',
        },
        {
          title: 'one month',
          type: '1month',
        },
        {
          title: 'three months',
          type: '3months',
        },
      ],
      pages: 1,
    }
  },
  mounted() {},
  created() {
    //   TODO:
    // this.getyuebaoDetail();
  },
  methods: {
    // 获取余额宝详情
    async getyuebaoDetail() {
      const { data } = await yuebaoDetail()
      console.log(data)
    },
    // 获取余额宝详情列表
    async getyuebaoDetailList() {
      const res = await yuebaoDetaileList({
        date: this.tabbarPageInfo[this.active].type,
        page: this.pages,
      })
      console.log(res)
    },
  },
}
</script>

<style lang="less" scoped>
.yuebao {
  position: relative;
  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      // background: -webkit-linear-gradient(left, #fc6925, #fd942e);
    }
    /deep/ .van-nav-bar__title {
      // color: #fff;
    }
    /deep/ .van-nav-bar__right {
      // color: #fff;
    }
    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }
  .Amount-box {
    width: 100%;
    height: 500px;
    background: -webkit-linear-gradient(left, #fc6925, #fd942e);
    padding-top: 80px;
    .Amount {
      color: #fff;
      text-align: center;
    }
    .time {
      color: #fff;
      text-align: center;
      font-size: 80px;
      margin: 30px 0;
    }
    .Earnings {
      width: 560px;
      height: 82px;
      border-radius: 41px;
      margin: auto;
      background-color: #fff;
      font-size: 40px;
      text-align: center;
      line-height: 82px;
      span {
        color: #e04f1e;
        font-size: 50px;
      }
    }
  }
  .Income-box {
    position: absolute;
    top: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 630px;
    height: 250px;
    padding: 25px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #eee;
    z-index: 99;
    .Accumulated,
    .annualized {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
    .income-item {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
    .title {
      font-size: 30px;
      color: #999;
      margin-bottom: 15px;
    }
    .btn {
      width: 300px;
      height: 80px;
      border-radius: 20px;
      text-align: center;
      line-height: 80px;
      font-size: 40px;
    }
    .Roll-out {
      background-color: #fde1d5;
      color: #ed4c02;
    }
    .Shift-to {
      background-color: #fb6b26;
      color: #fff;
    }
  }
  .detailed {
    position: relative;
    height: 200px;
    width: 100%;
    background-color: #fff;
    .returns {
      position: absolute;
      bottom: 10px;
      left: 20px;
      font-size: 30px;
      color: #999;
    }
  }
  .tab-box {
    /deep/ .van-tabs__nav--line {
      background-color: #f4f8f9;
    }
  }
}
</style>
